<template>
  <div class="login-page">
    <notification-message ref="notification" />
    <div class="left-section">
      <div class="logo">
        <!-- Add your logo image here -->
        <img src="../assets/ecotrade.png" alt="Logo" />
      </div>
      <div class="login-header">
        <h2>Reset</h2>
      </div>
      <div class="sub-title">
        <p>Welcome! Enter your details</p>
      </div>
      <div class="form">
        <div class="form-group">
          <!-- <label for="username">Username</label> -->
          <b-form-input
            type="password"
            placeholder="Enter old Password"
            id="oldPassword"
            v-model="oldPassword"
            required
            name="oldPassword"
          />
        </div>
        <div class="form-group">
          <!-- <label for="password">Password</label> -->
          <div class="password-field">
            <b-form-input
              type="password"
              placeholder="Enter new password"
              id="password"
              v-model="password"
              required
              name="password"
            />
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <div class="form-group">
          <b-button @click="login">Submit</b-button>
        </div>
        <div v-if="loginStatus === 'error'" class="error-message">
          {{ loginMessage }}
        </div>
      </div>
    </div>
    <section class="right-section">
      <h1>hello</h1>
      <!-- Add your image here -->
      <img src="../assets/loginpage.jpg" alt="Image" />
    </section>
  </div>
</template>

<script>
import { BFormInput, BButton } from "bootstrap-vue";
import NotificationMessage from "./NotificationMessage.vue"; // Adjust the import path

export default {
  components: {
    BButton, // Register the BButton component
    BFormInput,
    NotificationMessage,
  },
  data() {
    return {
      username: "",
      password: "",
      oldPassword:"",
      loginMessage: "",
      loginStatus: "",
    };
  },
  methods: {
    async login() {
      try {
        const email = this.$route.query.email;
        const response = await fetch("https://api.ecotrade.co.in/api/auth/reset", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            tempPassword: this.oldPassword,
            newPassword: this.password,
          }),
        });

        const data = await response.json();

        // Handle the API response here (e.g., check for success or error status)
        if (response.ok) {
          console.log(data);
          this.$refs.notification.showNotification(
              "Successfully reset password!",
              "success"
            );
          // Redirect the user to the desired route after successful login
          this.$router.push("/");
          // API call was successful, do something with the response data
          console.log("Reset successful:", data);
        } else {
          // API call returned an error, handle the error
          console.error("reset failed:", data);

          // Show the login failure message
          this.$refs.notification.showNotification(data.message, "error");
        }
      } catch (error) {
        // Handle any network or fetch-related errors
        console.error("Error occurred:", error);
        this.$refs.notification.showNotification(error.message, "error");

      }
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 1; /* Change the order to display left section below on small screens */
}

.logo img {
  /* margin-top: 1rem; */
  margin-left: 1rem; /* Add space to the left in big screens */
  width: 250px;
  height: 250px;
}
/* .logo img {
  
} */
.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.login-header {
  margin-top: 2rem;
}

.sub-title {
  margin-top: 1rem;
}

.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  padding: 0.5rem;
  width: 200px;
}

.password-field {
  position: relative;
}

input[type="password"] {
  padding-right: 2rem;
}

.fa-lock {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}

.remember-me {
  display: flex;
  align-items: center;
}

.forgot-password {
  margin-left: 1rem;
  text-decoration: none;
}

button {
  padding: 0.5rem 1rem;
  background-color: #01567b;
  color: white;
  border: none;
  cursor: pointer;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2; /* Change the order to display right section above on small screens */
  position: relative;
  overflow: hidden;
  background-color: #01567b;
  /* background-size: cover; */
}

.right-section::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('image.png');  */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.7;
  z-index: -1;
}

/* .right-section img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 1;
} */

/* Media query for small screens */
@media (max-width: 768px) {
  .right-section {
    display: none; /* Hide right section on small screens */
  }

  .left-section {
    order: 1; /* Display left section above on small screens */
  }

  .logo img {
    width: 200px; /* Adjust the width for small screens */
    height: 200px; /* Adjust the height for small screens */
    margin: 0 auto; /* Center the logo horizontally on small screens */
    margin-top: 0; /* Remove the top margin on small screens */
    margin-bottom: 0; /* Add space below the logo on small screens */
  }
}
</style>
