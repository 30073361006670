<template>
  <footer class="footer">
    <p class="footer-text">
      Copyright © 2023 EcoTrade | All Rights Reserved
    </p>
  </footer>
</template>
<script>
export default {
  name: "footer-section"
};
</script>

<style scoped>
/* Common styles for header and navigation links */
.footer {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 10vh;
}

.footer-text {
  font-size: 14px;
  color: #888;
}
</style>