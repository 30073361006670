<template>
    <div v-if="isVisible" class="notification" :class="notificationType">
      {{ message }}
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
        message: "",
        notificationType: "", // "success" or "error"
      };
    },
    methods: {
      showNotification(message, type = "success") {
        this.message = message;
        this.notificationType = type;
        this.isVisible = true;
  
        setTimeout(() => {
          this.isVisible = false;
        }, 5000); // Auto-hide after 5 seconds (adjust as needed)
      },
    },
  };
  </script>
  
  <style scoped>
  .notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    z-index: 99999;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .success {
    background-color: #28a745; /* Green */
  }
  
  .error {
    background-color: #dc3545; /* Red */
  }
  </style>
  