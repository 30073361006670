<template>
  <div class="hello">
    <header>
      <a href="/"><img class="logo" alt="Vue logo" src="../assets/logo2.png" >
      </a>
      <nav class="nav_links">
        <ul>
          <li v-if="userRole === 'ADMIN'"><a href="/user">User Management</a></li>
          <li v-if="userRole === 'SUPER_ADMIN'"><a href="/admin">Admin Management</a></li>
          <li><a href="/trading">Trade Page</a></li>
        </ul>
      </nav> 
      <a class="cta" href="#"><button class="oval-button" @click="logout">Logout</button></a>
    </header>
  </div>
</template>

<script>
import parseJwt from "../util/util";
export default {
  name: "HeaderPage",
  props: {
    msg: String,
  },
  async created() {
    // Remove the JWT token from the local storage on page load
    this.token = localStorage.getItem("jwtToken");
    const decodedToken = parseJwt(this.token);
    this.userRole = decodedToken.role;
    },
  methods: {

    logout() {
      // Clear the JWT token from the local storage
      localStorage.removeItem("jwtToken");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
/* Common styles for header and navigation links */
header,
nav.nav_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10%;
}

.logo {
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

li {
  margin: 0 10px;
}
.cta {
  display: inline-block;
  text-decoration: none;
  transition: color 0.3s ease;
}

.cta:hover {
  color: #0088a9;
}

.oval-button {
  padding: 5px 15px;
  border: 1px solid rgba(0, 86, 123, 1);
  border-radius: 50px;
  /* font-weight: 500; */
  font-size: 16px;
  color: rgba(0, 86, 123, 1);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.oval-button:hover {
  background-color: rgba(0, 86, 123, 1);
  color: #fff;
}

a {
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: rgba(0, 86, 123, 1);
  transition: color 0.3s ease;
}

a:hover {
  color: #0088a9;
}
.logo{
  width: 150px;
}

/* Styles for small screens */
@media (max-width: 768px) {
  header {
    padding: 20px 5%;
  }

  .nav_links {
    display: none;
  }

  /* .cta {
    display: none;
  } */

  .logo {
    margin-right: auto;
  }

  ul {
    flex-direction: column;
  }

  li {
    margin: 10px 0;
  }
}
</style>