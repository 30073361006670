<template>
  <div class="disclaimer-page">
    <notification-message ref="notification" />
    <div class="disclaimer-content-container">
      <div class="disclaimer-container">
        <div class="disclaimer-title">Disclaimer</div>
        <div class="disclaimer-content">
          <div class="disclaimer-scroll">
            <div class="disclaimer-scroll">
              <p>
                1. No Financial Advice: Our services and any information
                provided on our portal are not intended as financial advice. We
                do not make any guarantees or warranties regarding the accuracy,
                reliability, or completeness of the information provided.
                Clients should consult with a qualified financial advisor before
                making any investment decisions.
              </p>
              <p>
                2. Market Risks: We provide IT Platform for Algo trading, it
                involves inherent risks, including but not limited to market
                volatility, liquidity risks, and the potential for financial
                loss. Clients should be aware of these risks and understand that
                past performance is not indicative of future results.
              </p>
              <p>
                3. No Liability for Trading Results: We do not guarantee or
                assume any responsibility for the profitability or success of
                any trading strategies employed by clients using our services.
                Clients are solely responsible for their trading decisions and
                outcomes.
              </p>
              <p>
                4. Technical Risks: While we strive to provide reliable and
                uninterrupted services, we cannot guarantee the absence of
                technical issues, software bugs, or cyber threats that may
                impact the performance or availability of our platform. Clients
                should be aware of these risks and take appropriate precautions.
              </p>
              <p>
                5. Third-Party Content: Our portal may contain links or
                references to third-party websites, tools, or resources. We do
                not endorse or take responsibility for the accuracy,
                reliability, or legality of the content provided by these third
                parties. Clients should use such resources at their own
                discretion and risk.
              </p>
              <p>
                6. Regulatory Compliance: Clients are responsible for ensuring
                their compliance with all applicable laws, regulations, and
                licensing requirements related to algorithmic trading in their
                jurisdiction. We do not provide legal advice or guarantee
                compliance with specific regulations.
              </p>
              <p>
                7. Data Security: While we implement reasonable measures to
                protect client data and ensure confidentiality, no method of
                data transmission or storage is completely secure. Clients
                should be aware of the inherent security risks associated with
                using online platforms and take necessary precautions to protect
                their sensitive information.
              </p>
              <p>
                8. Changes to Services: We reserve the right to modify, suspend,
                or discontinue any part of our services or the portal at any
                time without prior notice. We are not liable for any loss or
                inconvenience resulting from such changes.
              </p>
              <p>
                9. Limitation of Liability: To the fullest extent permitted by
                law, we disclaim any liability for any direct, indirect,
                incidental, consequential, or punitive damages arising out of or
                in connection with the use of our services or the portal.
              </p>
              <p>
                10. Intellectual Property: All intellectual property rights,
                including but not limited to trademarks, logos, and copyrighted
                content, displayed on our portal are the property of their
                respective owners. Unauthorized use of such materials is
                strictly prohibited.
              </p>
            </div>
          </div>
        </div>
        <b-button class="accept-button" variant="primary" @click="acceptTerms"
          >Accept</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import parseJwt from "../util/util";
import NotificationMessage from "./NotificationMessage.vue"; // Adjust the import path

export default {
  components: {
    BButton,
    NotificationMessage,
  },
  data() {
    return {};
  },
  methods: {
    async acceptTerms() {
      try {
        const token = localStorage.getItem("jwtToken");
        const { id } = parseJwt(token);
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");
        const response = await fetch(
          "https://api.ecotrade.co.in/api/terms/accept",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              userId: id,
              isAccepted: true,
              dateAccepted: new Date().toISOString(),
            }),
          }
        );

        const data = await response.json();

        // Handle the response data
        if (response.ok) {
          // Terms accepted successfully
          console.log(data.message);
          this.$router.push("/trading");
          // You can perform further actions here if needed
        } else {
          this.$refs.notification.showNotification(data.message, "error");
          // Handle other status codes and errors
          console.error(data.message);
        }
      } catch (error) {
        console.error("Error accepting terms:", error);
        this.$refs.notification.showNotification(error.message, "error");
      }
    },
  },
};
</script>

<style>
/* Other styles remain the same */

.disclaimer-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.disclaimer-page .div {
  background-color: #ffffff;
  border: 1px none;
  height: 1024px;
  position: relative;
  width: 1440px;
}

.disclaimer-page .overlap {
  left: 60px;
  position: absolute;
  top: 188px;
  width: 610px;
}

.disclaimer-page .login-button-group {
  height: 40px;
  left: 23px;
  position: absolute;
  top: 751px;
  width: 152px;
}

.disclaimer-page .overlap-group {
  height: 40px;
  position: relative;
  width: 150px;
}

.disclaimer-page .login-button {
  align-items: flex-start;
  background-color: #01567b;
  border-radius: 4px;
  display: flex;
  height: 30px;
  left: 0;
  padding: 6px 6px 6px 8px;
  position: absolute;
  top: 10px;
  width: 150px;
}

.disclaimer-page .text {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  margin-bottom: -6px;
  padding: 4px 8px 4px 6px;
  position: relative;
}

.disclaimer-page .label {
  color: var(--x03-on-primary-high-emphasis);
  font-family: "Caladea", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.disclaimer-page .i-ACCEPT {
  color: #ffffff;
  font-family: "Caladea", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 32px;
  left: 18px;
  letter-spacing: 1.25px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 109px;
}

.disclaimer-page .text-wrapper {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: absolute;
  top: 405px;
  width: 544px;
}
.disclaimer-scroll {
  /* Add styles to create a scrollable area within the container */
  max-height: 600px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
}

.disclaimer-page .p {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: absolute;
  top: 290px;
  width: 544px;
}

.disclaimer-page .text-wrapper-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: absolute;
  top: 515px;
  width: 544px;
}

.disclaimer-page .text-wrapper-3 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: absolute;
  top: 625px;
  width: 544px;
}

.disclaimer-page .text-wrapper-4 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0.25px;
  line-height: 20px;
  position: absolute;
  top: 190px;
  width: 544px;
}

.disclaimer-page .terms-n-con-group {
  left: 0;
  position: absolute;
  top: 0;
  width: 610px;
}

.disclaimer-page .header-instance {
  height: 132px !important;
  left: 20px !important;
  mix-blend-mode: normal !important;
  width: 590px !important;
}

.disclaimer-page .design-component-instance-node {
  color: #979797 !important;
  font-family: "Caladea", Helvetica !important;
  font-size: 32px !important;
  left: 35px !important;
  letter-spacing: 0.19px !important;
  top: 44px !important;
}

.disclaimer-page .header-2 {
  color: #121212 !important;
  font-family: "Caladea", Helvetica !important;
  font-size: 36px !important;
  left: 35px !important;
  top: 12px !important;
}

.disclaimer-page .disclaimer-image {
  height: 800px;
  left: 760px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 680px;
}

.disclaimer-page .ecotrade {
  height: 150px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 150px;
}

.disclaimer-container {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.disclaimer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.disclaimer-content {
  font-size: 14px;
  line-height: 1.6;
}

.disclaimer-content li {
  list-style: disc;
  margin-left: 20px;
}

.disclaimer-content p {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .ecotrade,
  .disclaimer-image {
    display: none; /* Hide right section on small screens */
  }
}

@media (max-width: 767px) {
  .disclaimer-content {
    /* Adjust the font-size and line-height for mobile devices */
    font-size: 14px;
    line-height: 1.6;
  }

  .accept-button {
    /* Center the button on mobile devices */
    display: block;
    margin: 20px auto;
  }
}
.disclaimer-page {
  /* Add styles as needed for the main container */
  /* For example: background-color, padding, etc. */
  background-color: #ffffff;
  padding: 20px;
}

.disclaimer-content-container {
  /* Add styles to arrange disclaimer and image side by side */
  display: flex;
  flex-wrap: wrap;
}

.disclaimer-container {
  /* Add styles for the disclaimer container */
  /* For example: width, margin, etc. */
  flex: 1 0 60%; /* Adjust the width as needed */
  margin-right: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 20px;
}

.image-container {
  /* Add styles for the image container */
  /* For example: width, margin, etc. */
  flex: 1 0 40%; /* Adjust the width as needed */
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .disclaimer-content-container {
    flex-direction: column; /* Stack disclaimer and image on mobile */
  }

  .disclaimer-container {
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 20px; /* Add some space between disclaimer and image on mobile */
  }

  .image-container {
    justify-content: center; /* Center the image on mobile */
  }
}
</style>
