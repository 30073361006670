<template>
  <div class="hello">
    <header-page> </header-page>
    <div class="token-box">
      <div class="user-component">
        <notification-message ref="notification" />
        <div class="left-column">
          <div class="search-box">
            <div class="input-container">
              <input class="oval-input" type="text" placeholder="Search" />
              <button class="search-button">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>

          <table class="user-table">
            <!-- Table headers -->
            <thead>
              <tr>
                <th>Full name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Pincode</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Action</th>
              </tr>
            </thead>
            <!-- Table body -->
            <tbody>
              <tr v-for="(user, index) in users" :key="index">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.phone }}</td>
                <td>{{ user.address }}</td>
                <td>{{ user.pincode }}</td>
                <td>{{ user.city }}</td>
                <td>{{ user.state }}</td>
                <td>{{ user.country }}</td>
                <td>
                  <span @click="editUser(user)">
                    <i class="clickable-icon fas fa-edit"></i>
                  </span>
                  <span @click="deleteUser(user.id)">
                    <i
                      class="clickable-icon fas fa-trash"
                      @click="deleteUser(user.id)"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="right-column">
          <button class="oval-button" @click="showCreateUserForm">Create User</button>
          <div class="modal-overlay" v-if="isUserFormVisible">
          
              <div class="user-form-modal">
                <form class="user-form">
                  <h3>{{ editUserData ? "Edit User" : "Create User" }}</h3>
                  
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.name"
                    placeholder="Full Name"
                  />
                  <input
                    class="oval-input"
                    type="email"
                    v-model="formData.email"
                    placeholder="Email"
                  />
                  <input
                    class="oval-input"
                    type="tel"
                    v-model="formData.phone"
                    placeholder="Phone Number"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.aadhar_no"
                    placeholder="Aadhar Number"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.pan_no"
                    placeholder="PAN Number"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.address"
                    placeholder="Address"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.pincode"
                    placeholder="Pincode"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.city"
                    placeholder="City"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.state"
                    placeholder="State"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.country"
                    placeholder="Country"
                  />

    
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.quantity"
                    placeholder="Quantity"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.add_plan_type"
                    placeholder="Additional Plan Type"
                  />
                  <select class="oval-input" v-model="formData.user_token_type">
                    <option value="ANGEL">ANGEL</option>
                    <option value="ZERODHA">ZERODHA</option>
                  </select>
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.login_userId"
                    placeholder="Login User ID"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.login_password"
                    placeholder="Login Password"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.login_pin"
                    placeholder="Login PIN"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.api_key"
                    placeholder="API Key"
                  />
                  <input
                    class="oval-input"
                    type="text"
                    v-model="formData.api_secret"
                    placeholder="API Secret"
                  />
                  <button class="oval-button" @click.prevent="submitUser">
                    {{ editUserData ? "Update" : "Submit" }}
                  </button>
                  <button class="oval-button" @click.prevent="hideUserForm">
                    Cancel
                  </button>
                </form>
              </div>
   
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderPage from "./HeaderPage.vue";
import parseJwt from "../util/util";
import NotificationMessage from "./NotificationMessage.vue"; // Adjust the import path

export default {
  data() {
    return {
      isUserFormVisible: false,
      users: [],
      loginStatus: false,
      formData: {
        name: "",
        email: "",
        phone: "",
        aadhar_no: "",
        pan_no: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        quantity: "",
        add_plan_type: "",
        user_token_type: "",
        login_userId: "",
        login_password: "",
        login_pin: "",
        api_key: "",
        api_secret: "",
      },
      editUserData: null,
    };
  },
  components: {
    HeaderPage,
    NotificationMessage,
  },
  mounted() {
    // Fetch data from the API when the component is mounted
    this.fetchUserData();
  },
  methods: {
    async fetchUserData() {
      try {
        const token = localStorage.getItem("jwtToken");
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");
        const response = await fetch(
          "https://api.ecotrade.co.in/api/user/get",
          {
            method: "GET",
            headers: headers,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.users = data; // Update the users data property with the received data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    hideUserForm() {
      this.isUserFormVisible = false;
    },
    async submitUser() {
      try {
        const token = localStorage.getItem("jwtToken");
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");

        if (this.editUserData) {
          // Editing an existing user
          const response = await fetch(
            `https://api.ecotrade.co.in/api/user/update/${this.editUserData.id}`,
            {
              method: "PUT", // or "PATCH" depending on your API
              headers: headers,
              body: JSON.stringify(this.editUserData),
            }
          );

          if (response.ok) {
            // Handle success for edit operation
            const responseData = await response.json();
            console.log("User edited:", responseData);
            this.isUserFormVisible = false;
            this.fetchUserData();
            this.$refs.notification.showNotification(
              "Successfully edited user!",
              "success"
            );
          } else {
            // Handle errors for edit operation
            const responseData = await response.json();
            this.loginStatus = "error";
            this.loginMessage = responseData.message;
            this.isUserFormVisible = true;
            this.$refs.notification.showNotification(
              this.loginMessage,
              "error"
            );
          }
        } else {
          // Creating a new user
          const response = await fetch(
            "https://api.ecotrade.co.in/api/user/create",
            {
              method: "POST",
              headers: headers,
              body: JSON.stringify(this.formData),
            }
          );

          if (response.ok) {
            // Handle success for create operation
            const responseData = await response.json();
            console.log("User created:", responseData);
            this.isUserFormVisible = false;
            this.fetchUserData();
            this.$refs.notification.showNotification(
              "Successfully created user!",
              "success"
            );
          } else {
            // Handle errors for create operation
            const responseData = await response.json();
            this.loginStatus = "error";
            this.loginMessage = responseData.message;
            this.isUserFormVisible = true;
            this.$refs.notification.showNotification(
              this.loginMessage,
              "error"
            );
          }
        }
      } catch (error) {
        console.error("Error creating/editing user:", error);
        this.loginStatus = "error";
        this.loginMessage = error.message;
        this.$refs.notification.showNotification(this.loginMessage, "error");
        this.isUserFormVisible = true;
        // Handle error, display an error message, etc.
      }
    },
    async deleteUser(userId) {
      try {
        // Make a DELETE request to the delete API endpoint
        const token = localStorage.getItem("jwtToken");
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        const response = await fetch(
          `https://api.ecotrade.co.in/api/user/delete/${userId}`, // Replace with the actual delete API endpoint URL
          {
            method: "DELETE",
            headers: headers,
          }
        );

        if (response.ok) {
          // Handle success, remove the deleted user from the local data (if needed), and display a success message
          // For example, if you have a "users" array in your component's data
          // You can find the index of the user with the given ID and remove them
          const deletedUserIndex = this.users.findIndex((u) => u.id === userId);
          if (deletedUserIndex !== -1) {
            this.users.splice(deletedUserIndex, 1); // Remove the deleted user from the array
          }

          // Show a success notification
          this.$refs.notification.showNotification(
            "User deleted successfully!",
            "success"
          );
        } else {
          // Handle errors, display error messages, etc.
          console.error("Error deleting user.");

          // Show an error notification
          this.$refs.notification.showNotification(
            "Error deleting user. Please try again later.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        // Handle errors, display error messages, etc.

        // Show an error notification
        this.$refs.notification.showNotification(
          "Error deleting user. Please try again later.",
          "error"
        );
      }
    },
    async editUser(user) {
      try {
        // Set the user data to be edited
        this.editUserData = { ...user };
        this.formData = this.editUserData;
        // Show the user form modal
        this.showUserForm();
      } catch (error) {
        console.error("Error editing user:", error);
        // Handle errors, display error messages, etc.
      }
    },

    showUserForm() {
      this.isUserFormVisible = true;
      // Clear the user data being edited when showing the form
      //this.editUserData = null;
    },
    showCreateUserForm(){
      this.isUserFormVisible = true;
      this.editUserData = null;
      this.formData = {
        name: "",
        email: "",
        phone: "",
        aadhar_no: "",
        pan_no: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        quantity: "",
        add_plan_type: "",
        user_token_type: "",
        login_userId: "",
        login_password: "",
        login_pin: "",
        api_key: "",
        api_secret: "",
      };
    }
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.user-form-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  width: 400px;
}
.user-component {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.left-column,
.right-column {
  margin-bottom: 20px;
}

.right-column {
  padding: 20px 5%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(219, 219, 219, 0.99);
}

h3 {
  text-align: center;
  color: rgba(0, 86, 123, 1);
}
@media (min-width: 1200px) {
  .user-component {
    flex-direction: row;
  }

  .left-column {
    flex: 1;
    margin-right: 20px;
  }
  .clickable-icon {
    cursor: pointer;
  }

  .right-column {
    flex: 1;
  }
}

.oval-input,
.oval-button {
  width: 100%;
  padding: 10px 20px;
  border-radius: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.oval-button {
  border: 1px solid rgba(0, 86, 123, 1);
  color: rgba(0, 86, 123, 1);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.oval-button:hover {
  background-color: rgba(0, 86, 123, 1);
  color: #fff;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.user-table th {
  background-color: rgba(0, 86, 123, 1);
  color: #fff;
}
.token-box {
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  padding: 30px 10%;
}

@media (max-width: 1200px) {
  .user-table th,
  .user-table td {
    font-size: 14px;
  }
}

.user-component {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.left-column,
.right-column {
  margin-bottom: 20px;
}

.right-column {
  padding: 20px 5%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(219, 219, 219, 0.99);
}

h3 {
  text-align: center;
  color: rgba(0, 86, 123, 1);
}

@media (min-width: 1024px) {
  .user-component {
    flex-direction: row;
  }

  .left-column {
    flex: 1;
    margin-right: 20px;
  }

  .right-column {
    flex: 1;
  }
}

.oval-input,
.oval-button {
  width: 100%;
  padding: 10px 20px;
  border-radius: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.oval-button {
  border: 1px solid rgba(0, 86, 123, 1);
  color: rgba(0, 86, 123, 1);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.oval-button:hover {
  background-color: rgba(0, 86, 123, 1);
  color: #fff;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.user-table th {
  background-color: rgba(0, 86, 123, 1);
  color: #fff;
}

.token-box {
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  padding: 30px 10%;
}

@media (max-width: 1200px) {
  .user-table th,
  .user-table td {
    font-size: 14px;
  }

  /* Adjustments for mobile view */
  .user-component {
    padding: 20px;
  }

  .left-column,
  .right-column {
    margin-bottom: 10px;
  }

  .right-column {
    padding: 20px;
    border: none;
    box-shadow: none;
  }
}

.user-table-container {
  overflow-x: auto;
  max-width: 100%;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Rest of your styles */

@media (max-width: 1200px) {
  /* Media queries for mobile view */

  .user-component {
    padding: 20px;
  }

  .left-column,
  .right-column {
    margin-bottom: 10px;
  }

  .right-column {
    padding: 20px;
    border: none;
    box-shadow: none;
  }
}
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
table thead tbody {
  display: table;
  width: 100%;
}

.search-box {
  position: relative;
  display: inline-block;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.oval-input {
  padding-right: 40px; /* Adjust this value to make space for the button */
  border: 1px solid #ccc;
  border-radius: 20px;
  height: 30px;
}

.search-button {
  position: absolute;
  right: 5px; /* Adjust this value to position the button inside the input */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.fas.fa-search {
  color: #555;
}
.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
</style>
