<template>
  <div>
    <div v-if="!loggedIn" class="login-status">
      <!-- Render Zerodha form if selectedTokenType is 'zerodha' -->
      <b-form v-if="tokenType === 'ZERODHA'">
        <b-form-group label="Zerodha Request Token">
          <b-form-input v-model="zerodha.requestToken"></b-form-input>
        </b-form-group>
        <b-form-group label="Zerodha Access Token">
          <b-form-input v-model="zerodha.accessToken"></b-form-input>
        </b-form-group>
        <b-form-group label="Zerodha Public Token">
          <b-form-input v-model="zerodha.publicToken"></b-form-input>
        </b-form-group>
      </b-form>

      <!-- Render Angel form if selectedTokenType is 'angel' -->
      <!-- Form for Angel token type -->
      <b-form v-if="tokenType === 'ANGEL'">
        <b-form-group label="Angel Feed Token">
          <b-form-input v-model="angel.totpToken"></b-form-input>
        </b-form-group>
      </b-form>

      <b-button
        @click="submitForm"
        variant="primary"
        class="save"
        :disabled="loading"
        >save</b-button
      >

      <div v-if="loading" class="overlay">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </div>
    <br />
    <span v-if="userRole === 'SUPER_ADMIN' && !renko">
      <b-button
        @click="startRenko"
        variant="primary"
        class="save"
        :disabled="loading"
        >start Renko csv</b-button
      >
    </span>
    <div v-if="loggedIn" class="login-status">
      <p>You are logged in to the {{ tokenType }} portal.</p>
      <b-button @click="logout(tokenType)" variant="danger" class="logout"
        >Logout</b-button
      >
    </div>
    <div v-if="renko" class="login-status">
      <p>You have started renko csv reading.</p>
      <b-button @click="logout('renko')" variant="danger" class="logout"
        >stop csv</b-button
      >
    </div>
    <notification-message ref="notification" />

    <div>
      <div class="timeline">
        <div
          v-for="eventData in eventsData"
          :key="eventData.id"
          class="timeline-event"
        >
          <div class="event-type">{{ eventData.event_type }}</div>
          <div class="event-date">{{ formatDate(eventData.event_date) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TokenUpdateForm from "./TokenUpdateForm.vue";
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import NotificationMessage from "./NotificationMessage.vue"; // Adjust the import path

import parseJwt from "../util/util";
export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    NotificationMessage,
  },
  data() {
    return {
      loading: true,
      platform: false,
      renko: false,
      loggedIn: false, // Add this property
      tokenType: "", // To store the selected token type (zerodha or angel)
      tokenTypes: ["zerodha", "angel"], // Options for the dropdown
      loginStatus: "",
      loginMessage: "",
      zerodha: {
        requestToken: "",
        accessToken: "",
        publicToken: "",
      },
      angel: {
        totpToken: "",
      },
      userId: "",
      token: "",
      eventsData: [],
    };
  },
  async created() {
    // Remove the JWT token from the local storage on page load
    this.token = localStorage.getItem("jwtToken");
    const decodedToken = parseJwt(this.token);
    this.tokenType = decodedToken.tokenType;
    this.userId = decodedToken.id;
    this.userRole = decodedToken.role;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this.token}`);
    headers.append("Content-Type", "application/json");
    try {
      const response = await fetch(
        "https://api.ecotrade.co.in/api/events/" + this.userId,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);

        this.eventsData = data; // Store the list of events
        this.checkLoginStatus();
        console.log(this.loggedIn);
      } else {
        // Handle error
        console.error("Error checking login status");
        this.$refs.notification.showNotification(data.message, "error");
      }
    } catch (error) {
      // Handle error
      console.error("Error checking login status:", error);
      this.$refs.notification.showNotification(error.message, "error");
    } finally {
      this.loading = false; // Set loading to false when data loading is complete
    }
  },
  methods: {
    async logout(type) {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${this.token}`);
      headers.append("Content-Type", "application/json");
      try {
        const response = await fetch(
          "https://api.ecotrade.co.in/api/events/" +
            this.userId +
            "/" +
            type +
            "/logout",
          {
            method: "GET",
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          if (type !== "renko") {
            this.loggedIn = false;
          } else {
            this.renko = false;
          }
          this.$refs.notification.showNotification(
            "Successfully logout to  platform!",
            "success"
          );
          window.location.reload();
        } else {
          // Handle error
          if (type !== "renko") {
            this.loggedIn = false;
          } else {
            this.renko = false;
          }
          console.error("Error checking logout status");
          this.$refs.notification.showNotification(
            "error while logout",
            "error"
          );
        }
      } catch (error) {
        // Handle error
        if (type !== "renk") {
          this.loggedIn = false;
        } else {
          this.renko = false;
        }

        console.error("Error checking login status:", error);
        this.$refs.notification.showNotification(error.message, "error");
      } finally {
        this.loading = false; // Set loading to false when data loading is complete
      }
    },
    submitForm() {
      this.loading = true;
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${this.token}`);
      headers.append("Content-Type", "application/json");
      // Make API call to submit the form data
      let formData = {};
      if (this.tokenType === "ZERODHA") {
        formData = {
          tokenData: {
            requestToken: this.zerodha.requestToken,
            accessToken: this.zerodha.accessToken,
            publicToken: this.zerodha.publicToken,
          },
          userId: this.userId,
        };
      } else if (this.tokenType === "ANGEL") {
        formData = {
          tokenData: {
            totpToken: this.angel.totpToken,
          },
          userId: this.userId,
        };
      }

      fetch(
        "https://api.ecotrade.co.in/api/token/" +
          this.tokenType.toLowerCase() +
          "/login",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle the API response
          console.log("Token created successfully:", data);
          this.loggedIn = true; // Set loggedIn to true on successful token creation
          this.loading = false;
          this.platform = true;
          this.$refs.notification.showNotification(
            "Successfully login to  platform!",
            "success"
          );
          window.location.reload();
        })
        .catch((error) => {
          // Handle API error
          this.loading = false;
          this.$refs.notification.showNotification(error.message, "error");
          console.error("Error creating token:", error.message);
        });
    },
    startRenko() {
      this.loading = true;
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${this.token}`);
      headers.append("Content-Type", "application/json");
      fetch("https://api.ecotrade.co.in/api/token" + "/startRenko", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ userId: this.userId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle the API response
          console.log("Token created successfully:", data);
          this.loading = false;
          this.renko = true;
          this.$refs.notification.showNotification(
            "Successfully login to  platform!",
            "success"
          );
          window.location.reload();
        })
        .catch((error) => {
          // Handle API error
          this.loading = false;
          this.$refs.notification.showNotification(error.message, "error");
          console.error("Error creating token:", error.message);
        });
    },
    checkLoginStatus() {
      // Sort events by event_date in descending order

      // Check if there's a login event and the latest event is not a logout event


      if (this.tokenType === "ZERODHA") {
        const hasLoginEvent = this.eventsData.filter(
          (event) =>
            event.event_type === "login-zerodha" ||
            event.event_type === "logout-zerodha"
        );
        if (hasLoginEvent && hasLoginEvent.length > 0) {
          if (
            hasLoginEvent[hasLoginEvent.length - 1].event_type ===
            "login-zerodha"
          ) {
            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }
        } else {
          this.loggedIn = false;
        }
      }

      if (this.tokenType === "ANGEL") {
        const hasLoginEvent = this.eventsData.filter(
          (event) =>
            event.event_type === "login-angel" ||
            event.event_type === "logout-angel"
        );
        if (hasLoginEvent && hasLoginEvent.length > 0) {
          if (
            hasLoginEvent[hasLoginEvent.length - 1].event_type === "login-angel"
          ) {
            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }
        } else {
          this.loggedIn = false;
        }
      }

      const hasStartEvent = this.eventsData.filter(
        (event) =>
          event.event_type === "login-renko" ||
          event.event_type === "logout-renko"
      );

      if (hasStartEvent && hasStartEvent.length > 0) {
        if (
          hasStartEvent[hasStartEvent.length - 1].event_type === "login-renko"
        ) {
          this.renko = true;
        } else {
          this.renko = false;
        }
      } else {
        this.renko = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); // Adjust format as needed
    },
  },
};
</script>
<style>
/* Other styles remain the same */

.token-form-container {
  /* Add styles for the token form container */
  /* For example: width, margin, etc. */
  flex: 1 0 40%; /* Adjust the width as needed */
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.form-group {
  /* Add styles for the form group (each field) */
  /* For example: margin-bottom, padding, etc. */
  margin-bottom: 20px;
}

.form-group label {
  /* Add styles for the label of each field */
  /* For example: font-size, font-weight, etc. */
  font-size: 16px;
  font-weight: bold;
}

.form-group input {
  /* Add styles for the input field of each field */
  /* For example: width, padding, etc. */
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.timeline {
  display: flex;
  flex-direction: column-reverse; /* Display events in reverse chronological order */
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 80px;
}

.timeline-event {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  background-color: #f9f9f9;
  width: 80%;
}

.event-type {
  font-weight: bold;
  margin-bottom: 5px;
}

.event-date {
  color: #666;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure the overlay is on top of other content */
}

/* Adjust the spinner's size if needed */
.overlay b-spinner {
  width: 10rem;
  height: 10rem;
}
</style>
