<template>
  <div>
    <b-form>
      <!-- Add form fields based on token type -->
      <b-form-group label="Login Password">
        <b-form-input v-model="formData.loginPassword"></b-form-input>
      </b-form-group>
      <!-- ... Add more fields as needed ... -->

      <!-- Display different buttons based on token type -->
      <b-button
        v-if="selectedTokenType === 'zerodha'"
        variant="primary"
        @click="updateZerodha"
        >Update</b-button
      >
      <b-button
        v-else-if="selectedTokenType === 'angel'"
        variant="primary"
        @click="updateAngel"
        >Update</b-button
      >
      <b-button v-else variant="primary" @click="start">Start</b-button>
    </b-form>
  </div>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    selectedTokenType: String,
  },
  data() {
    return {
      formData: {
        loginPassword: "",
        // Add more fields as needed
      },
    };
  },
  methods: {
    updateZerodha() {
      // Handle Zerodha update logic
      // Make API call or perform actions
    },
    updateAngel() {
      // Handle Angel update logic
      // Make API call or perform actions
    },
    start() {
      // Handle Start logic
      // Make API call or perform actions
    },
  },
};
</script>

<style>
/* Add styles as needed */
</style>
