import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../components/LoginPage.vue";
import ResetPage from "../components/ResetPage.vue";
import DisclaimerPage from "../components/DisclaimerPage.vue";
import TradingPage from "../components/TradingPage.vue";
import ForgetPage from "../components/ForgetPage.vue";
import UserPage from "../components/UserPage.vue";
import AdminPage from "../components/AdminPage.vue";
import parseJwt from "../util/util";

Vue.use(VueRouter);

// Function to check if the user is authenticated (replace this with your own authentication logic)
function isAuthenticated() {
  // Example: Check if the user is logged in and has a valid token or session
  const token = localStorage.getItem("jwtToken");
  return token !== null; // Return true if authenticated, otherwise return false
}

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/forget",
    name: "ForgetPage",
    component: ForgetPage,
  },
  {
    path: "/reset",
    name: "ResetPage",
    component: ResetPage,
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: DisclaimerPage,
    meta: {
      requiresAuth: true,
      requiredRole: ["SUPER_ADMIN", "ADMIN", "USER"],
    }, // Add meta information for authentication and required role
  },
  {
    path: "/trading",
    name: "TradingPage",
    component: TradingPage,
    meta: {
      requiresAuth: true,
      requiredRole: ["SUPER_ADMIN", "ADMIN", "USER"],
    },
  },
  {
    path: "/user",
    name: "user",
    component: UserPage,
    meta: {
      requiresAuth: true,
      requiredRole: [ "ADMIN"],
    }, // Add meta information for authentication and required role
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminPage,
    meta: {
      requiresAuth: true,
      requiredRole: ["SUPER_ADMIN"],
    }, // Add meta information for authentication and required role
  },
  {
    path: "*",
    redirect: "/",
  },
];

// Function to check if the user has a specific role
function hasRole(roles) {
  // Get the JWT token from local storage
  const token = localStorage.getItem("jwtToken");

  if (token) {
    // Decode the JWT token to extract the user information
    const decodedToken = parseJwt(token);

    console.log(decodedToken);

    // Extract the user role from the decoded token
    const userRole = decodedToken.role; // Replace 'role' with the property name in the token that represents the user's role

    // Compare the user role with the specified role
    return roles.includes(userRole);
  }

  // If there's no token or the role doesn't match, return false
  return false;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Global navigation guard to handle authentication and role-based authorization
// Global navigation guard to handle authentication
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiredRole = to.meta.requiredRole;

  if (requiresAuth) {
    // If the route requires authentication
    if (isAuthenticated()) {
      // If the user is authenticated

      if (requiredRole) {
        // If the route requires a specific role
        if (hasRole(requiredRole)) {
          // If the user has the required role, allow navigation to the route
          next();
        } else {
          // If the user doesn't have the required role, redirect to a different page or show an error message
          // For example, you can redirect to a 403 Forbidden page
          next("/forbidden");
        }
      } else {
        // If the route doesn't require a specific role, allow navigation to the route
        next();
      }
    } else {
      // If the user is not authenticated, redirect to the login page
      next("/");
    }
  } else {
    // If the route doesn't require authentication, allow navigation to the route
    next();
  }
});

export default router;
