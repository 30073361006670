<template class="body">
  <div class="hello">
    <header-page> </header-page>
      <div class="token-box">
        <h4>Welcome, {{fullName}}!</h4>
      <form>
        <TokenForm />
      </form>
    </div>
    </div>
    <!--
    <div class="token-box">
      <h4 class="trade-title">Trade History</h4>
      <p class="trade-text">
        THIS RESULT IS VALID FOR TODAY ONLY, WE DO NOT DIRECTLY OR INDIRECTLY
        MAKE ANY REFERENCE TO THE PAST OR EXPECTED FUTURE RETURN/PERFORMANCE OF
        THE ALGORITHM.
      </p>
      <table id="customers" class="table-container">
        <tr>
          <th>Signal Time</th>
          <th>Symbol</th>
          <th>Start</th>
          <th>Type</th>
          <th>Quantity</th>
          <th>Entry Price</th>
          <th>Exit Price</th>
          <th>P&L</th>
          <th>Cumulative P&L</th>
        </tr>
        <tr>
          <td>Alfreds Futterkiste</td>
          <td>Maria Anders</td>
          <td>Germany</td>
          <td>1</td>
          <td>11</td>
          <td>111</td>
          <td>1111</td>
          <td>11111</td>
          <td>111111</td>
        </tr>
        <tr>
          <td>Berglunds snabbköp</td>
          <td>Christina Berglund</td>
          <td>Sweden</td>
          <td>12</td>
          <td>121</td>
          <td>1211</td>
          <td>121211</td>
          <td>121111</td>
          <td>1112111</td>
        </tr>
        <tr>
          <td>Centro comercial Moctezuma</td>
          <td>Francisco Chang</td>
          <td>Mexico</td>
          <td>31</td>
          <td>131</td>
          <td>3111</td>
          <td>11131</td>
          <td>131111</td>
          <td>3111111</td>
        </tr>
        <tr>
          <td>Ernst Handel</td>
          <td>Roland Mendel</td>
          <td>Austria</td>
          <td>91</td>
          <td>161</td>
          <td>12311</td>
          <td>11311</td>
          <td>141111</td>
          <td>1511111</td>
        </tr>
        <tr>
          <td colspan="8">Königlich Essen</td>
          <td>999999</td>
        </tr>
      </table>
    </div>
    -->
</template>

<script>
import TokenForm from "./TokenForm.vue";
import HeaderPage from "./HeaderPage.vue";
import parseJwt from "../util/util";

export default {
  components: {
    TokenForm,
    HeaderPage
  },
  created(){
    this.token = localStorage.getItem("jwtToken");
    const decodedToken = parseJwt(this.token);
    this.fullName = decodedToken.fullName;
  },
  methods: {
    logout() {
      // Clear the JWT token from the local storage
      localStorage.removeItem("jwtToken");
      this.$router.push("/");
    },
  },
  name: "TradingPage",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  background-color: #f2f9f9;
}

img {
  width: 50px;
  height: 50px;
}

.hello {
  background-color: #ffffff;
}

button,
a {
  font-size: 16px;
  text-decoration: none;
  color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  padding: 10px 10%;
}

button {
  padding: 9px 25px;
  background-color: rgba(1, 86, 123, 1);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

button:hover {
  background-color: rgba(1, 86, 123, 0.8);
}

.logo {
  cursor: pointer;
}

.token-box {
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  padding: 30px 10%;
}

input[type="text"] {
  flex: 1;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border-radius: 4px;
}

input[type="text"]:focus {
  border: 1px solid #555;
}

.button-group {
  display: inline;
  margin-left: 50px;
  justify-content: space-between;
}

.start {
  margin: 20px;
}

.stop {
  color: #292929;
  background-color: #ffffff;
  border: 1px solid var(--outline, rgba(0, 0, 0, 0.12));
}

.stop:hover {
  background-color: rgba(1, 86, 123, 0.8);
  color: #fff;
}
.trade-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trade-text {
  color: #979797;
  font-size: 18px;
  letter-spacing: 1.5px;
  font-style: italic;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #01567b;
  color: white;
}

.table-container {
  /* Adjust the width of the container as needed */
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .header {
    flex-direction: row; /* Display the logo and button side by side */
    justify-content: space-between; /* Keep logo on the left and button on the right */
    padding: 10px 10%;
  }
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: 10px;
  }
  .token-box {
    margin-top: 30px;
  }
  input[type="text"] {
    width: 100%;
  }
  .table-container {
    width: 100%; /* Adjust the width of the container as needed */
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  #customers {
    width: max-content;
    /* display: block; Display the table as a block on small screens
    width: 100%; Ensure the table takes the full width available */
  }
  #customers thead,
  #customers tbody,
  #customers tr,
  #customers th,
  #customers td {
    display: block; /* Make table elements display as block on small screens */
  }
  /* Add spacing between cells */
  #customers th,
  #customers td {
    margin: 0;
    border: none;
    border-bottom: 1px solid #ddd;
    white-space: nowrap; /* Prevent text wrapping */
  }
  /* Add proper padding */
  #customers td {
    padding-left: 10px; /* Adjust as needed */
    position: relative;
  }
  /* Add labels to each cell */
  #customers td::before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
  }
}
</style>
