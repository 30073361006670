<template>
  <div class="login-page">
    <notification-message ref="notification" />
    <div class="left-section">
      <div class="logo">
        <!-- Add your logo image here -->
        <img src="../assets/ecotrade.png" alt="Logo" />
      </div>
      <div class="login-header">
        <h2>Login</h2>
      </div>
      <div class="sub-title">
        <p>Welcome! Enter your details</p>
      </div>
      <div class="form">
        <div class="form-group">
          <!-- <label for="username">Username</label> -->
          <b-form-input
            type="text"
            placeholder="Enter username"
            id="username"
            v-model="username"
            required
            name="username"
          />
        </div>
        <div class="form-group">
          <!-- <label for="password">Password</label> -->
          <div class="password-field">
            <b-form-input
              type="password"
              placeholder="Enter password"
              id="password"
              v-model="password"
              required
              name="password"
            />
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <div class="form-group">
          <div class="remember-me">
            <input type="checkbox" id="remember-me" v-model="rememberMe" />
            <label for="remember-me">Remember me</label>
          </div>
          <router-link to="/forget"><a href="#" class="forgot-password">Forgot password?</a></router-link>
        </div>
        <div class="form-group">
          <b-button @click="login">Submit</b-button>
        </div>
      </div>
    </div>
    <section class="right-section">
      <img src="../assets/loginpage.jpg" alt="Image" />
    </section>
  </div>
</template>

<script>
import { BFormInput, BButton } from "bootstrap-vue";
import parseJwt from "../util/util.js";
import NotificationMessage from "./NotificationMessage.vue"; // Adjust the import path

export default {
  components: {
    BButton, // Register the BButton component
    BFormInput,
    NotificationMessage,
  },
  data() {
    return {
      username: "",
      password: "",
      loginMessage: "",
      loginStatus: "",
    };
  },
  created() {
    // Remove the JWT token from the local storage on page load
    localStorage.removeItem("jwtToken");
    // Check if there are saved credentials in the local storage
    const rememberedUsername = localStorage.getItem("rememberedUsername");
    const rememberedPassword = localStorage.getItem("rememberedPassword");

    if (rememberedUsername && rememberedPassword) {
      // Pre-fill the login form with the saved credentials
      this.username = rememberedUsername;
      this.password = rememberedPassword;
      this.rememberMe = true; // Set the "Remember Me" checkbox to checked
    }
  },
  methods: {
    async login() {
      if (this.rememberMe) {
        // Save the username and password in the local storage
        localStorage.setItem("rememberedUsername", this.username);
        localStorage.setItem("rememberedPassword", this.password);
      } else {
        // If "Remember Me" is not checked, remove any previously saved credentials
        localStorage.removeItem("rememberedUsername");
        localStorage.removeItem("rememberedPassword");
      }
      try {
        const response = await fetch("https://api.ecotrade.co.in/api/auth/signin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.username,
            password: this.password,
          }),
        });

        const data = await response.json();

        // Handle the API response here (e.g., check for success or error status)
        if (response.ok) {
          if (data.data.resetRequired) {
            this.$router.push({
              path: "/reset",
              query: { email: this.username },
            });
          }
          const token = data.data.token; // Replace 'token' with the actual property name of the JWT token in the response

          console.log(data);
          // Store the token in the browser's local storage
          localStorage.setItem("jwtToken", token);
          const decodedToken = parseJwt(token);
          this.loginMessage = "";
          this.loginStatus = "success";
          // Redirect the user to the desired route after successful login
          const url = "https://api.ecotrade.co.in/api/terms/accept/" + decodedToken.id;
          const headers = new Headers();
          headers.append("Authorization", `Bearer ${token}`);
          headers.append("Content-Type", "application/json");

          await fetch(url, {
            method: "GET",
            headers: headers,
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              if (data?.terms?.is_accepted) {
                this.$router.push("/trading");
              }
            })
            .catch((error) => {
              // Handle errors here
              console.error("Fetch error:", error);
              this.$router.push("/disclaimer");
            });

          // API call was successful, do something with the response data
          console.log("Login successful:", data);
          this.$refs.notification.showNotification(
              "Successfully Login!",
              "success"
            );
        } else {
          // API call returned an error, handle the error
          console.error("Login failed:", data);

          // Show the login failure message
          this.$refs.notification.showNotification(data.message, "error");
        }
      } catch (error) {
        // Handle any network or fetch-related errors
        console.error("Error occurred:", error);
        this.$refs.notification.showNotification(error.message, "error");

      }
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 1; /* Change the order to display left section below on small screens */
}

.logo img {
  /* margin-top: 1rem; */
  margin-left: 1rem; /* Add space to the left in big screens */
  width: 250px;
  height: 250px;
}
/* .logo img {
  
} */
.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.login-header {
  margin-top: 2rem;
}

.sub-title {
  margin-top: 1rem;
}

.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  padding: 0.5rem;
  width: 200px;
}

.password-field {
  position: relative;
}

input[type="password"] {
  padding-right: 2rem;
}

.fa-lock {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}

.remember-me {
  display: flex;
  align-items: center;
}

.forgot-password {
  margin-left: 1rem;
  text-decoration: none;
}

button {
  padding: 0.5rem 1rem;
  background-color: #01567b;
  color: white;
  border: none;
  cursor: pointer;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2; /* Change the order to display right section above on small screens */
  position: relative;
  overflow: hidden;
  background-color: #01567b;
  /* background-size: cover; */
}

.right-section::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('image.png');  */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.7;
  z-index: -1;
}

.right-section img {
  max-width: 100%;
  height: 90vh;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .right-section {
    display: none; /* Hide right section on small screens */
  }

  .left-section {
    order: 1; /* Display left section above on small screens */
  }

  .logo img {
    width: 200px; /* Adjust the width for small screens */
    height: 200px; /* Adjust the height for small screens */
    margin: 0 auto; /* Center the logo horizontally on small screens */
    margin-top: 0; /* Remove the top margin on small screens */
    margin-bottom: 0; /* Add space below the logo on small screens */
  }
}
</style>
